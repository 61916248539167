import React, { Component } from "react"
import styles from '../styles/about-us.module.css'
import Navigation from "../components/Navigation/Navigation"
import { Link } from "gatsby"
import circlesIcon from '../images/circles.svg'
import peopleIcon from '../images/people.svg'
import smallSchoolIcon from '../images/school-small.svg'
import briefCaseIcon from '../images/briefcase.svg'
import laptopIcon from '../images/laptop.svg'
import ChampionsIcon from '../images/teach-board.svg'
import SchoolIcon from '../images/school.svg'
import TrainingIcon from '../images/teach.svg'
import SurveyIcon from '../images/survey.svg'
import CupIcon from '../images/cup.svg'
import PositionsIcon from '../images/position.svg'
import WinnerIcon from '../images/competition.svg'
import IGLogo from "../images/ig.svg"
import FBLogo from "../images/fb.svg"
import TWLogo from "../images/tw.svg"
import MouseIcon from '../images/mouse.svg'
import Header from "../components/Header/Header"

class AboutUs extends Component {
    mainRef = React.createRef();

    scroll = () => this.mainRef.current.scrollIntoView({behavior: "smooth"});

    render() {
        return (
            <>
                <Header title={'About Us | BDEI'}/>
                <header className={styles.header}>
                    <Navigation absolute/>
                    <div className={styles.header__overlay}/>
                    <h1 className={styles.header__header}>
                        A better way to educate public school students about technology.
                    </h1>
                    <p className={styles.header__subtext}>
                        We help teach public school students the basic computer skills needed to help get familiar with
                        recent technology.
                    </p>
                    <Link className={styles.header__link} to='/become-a-champion'>
                        Sign up
                    </Link>
                    <button className={styles.header__mouseBtn} onClick={this.scroll}>
                        <img src={MouseIcon} alt="Mouse Icon"/>
                    </button>
                </header>
                <main ref={this.mainRef}>
                    <section className={styles.aboutUs}>
                        <p className={styles.sectionLetter}>
                            B
                        </p>
                        <h4 className={styles.sectionHeader}>
                            ABOUT US
                        </h4>
                        <p className={styles.aboutUs__text}>
                            BDEI is an experiential learning computer education and STEM program that aims to train 500,000 public school students between ages 8 -18 across all the states in Nigeria
                        </p>
                        <div>
                            <p className={styles.aboutUs__goalText}>
                                The goal of the project is to introduce learners in underserved communities to the world of digital literacy and equip them with the basic digital knowledge that is required to succeed in the 21st century world and workplace.
                            </p>
                            <p className={styles.aboutUs__goalText}>
                            The training will cover critical areas like collaboration, creativity, critical thinking, communication, problem-solving, citizenship, etc.
                            </p>
                            <p className={styles.aboutUs__goalText}>
                                {/*BDEI will train 500,000 students between the ages of 8 – 18 across underserved communities in Nigeria.*/}
                                The training will also cover Introduction to computing, productivity tools, basic web skills, workplace essentials, big data, cloud technologies and cybersecurity in the intermediate stage.
                            </p>
                            {/*<p className={styles.aboutUs__goalText}>*/}
                            {/*    The BDEI program will afford beneficiaries access to basic digital literacy skills that*/}
                            {/*    will make them rightly positioned for future opportunities in the technology space.*/}
                            {/*</p>*/}
                        </div>
                        <img src={circlesIcon} className={styles.aboutUs__icon} alt="Circles"/>
                    </section>
                    <section>
                        <span className={[styles.objectives__line, styles.objectives__lineTop].join(" ")}/>
                        <p className={styles.sectionLetter}>
                            O
                        </p>
                        <h4 className={styles.sectionHeader}>
                            OBJECTIVES
                        </h4>
                        <p className={[styles.sectionSubtext, styles.objectives__subtext].join(" ")}>
                            These are some of the objectives we are determined to achieve
                        </p>
                        <div className={styles.objectivesGrid}>
                            <p className={styles.objectives__objective}>
                                <img src={peopleIcon} className={styles.objectives__objectiveIcon} alt="People"/>
                                <span>
                            To increase the number of digital literate children across Nigeria yearly
                        </span>
                            </p>
                            <p className={styles.objectives__objective}>
                                <img src={smallSchoolIcon} className={styles.objectives__objectiveIcon} alt="People"/>
                                <span>
                            To increase the number of children willing to study and participate in STEM at a tertiary level yearly
                        </span>
                            </p>
                            <p className={styles.objectives__objective}>
                                <img src={briefCaseIcon} className={styles.objectives__objectiveIcon} alt="People"/>
                                <span>
                            To ensure that children are exposed to the skill set needed for advanced learning and decent work
                        </span>
                            </p>
                            <p className={styles.objectives__objective}>
                                <img src={laptopIcon} className={styles.objectives__objectiveIcon} alt="People"/>
                                <span>
                            To empower children in rural communities with digital skills required for the future of work and advanced learning for the 21st century yearly
                        </span>
                            </p>
                        </div>
                        <span className={[styles.objectives__line, styles.objectives__lineBottom].join(" ")}/>
                    </section>
                    <section className={styles.outcomes}>
                        <div className={styles.outcomes__textWrapper}>
                            <p className={[styles.sectionLetter, styles.outcomes__letter].join(" ")}>
                                E
                            </p>
                            <h4 className={styles.sectionHeader}>
                                EXPECTED OUTCOMES
                            </h4>
                            <p className={styles.sectionSubtext}>
                                These are some of the objectives we are determined to achieve
                            </p>
                            <div className={styles.outcomes__gradient}/>
                        </div>
                        <div className={styles.outcomes__grid}>
                            <div className={[styles.outcomes__outcome, styles.outcomes__outcomeBlue].join(" ")}>
                                <h2 className={styles.outcomes__outcomeHeader}>
                                    50
                                </h2>
                                <p className={styles.outcomes__outcomeSubtext}>
                                    50 Master trainers who will become BDEI champions
                                </p>
                            </div>
                            <div className={[styles.outcomes__outcome, styles.outcomes__outcomeRed].join(" ")}>
                                <h2 className={styles.outcomes__outcomeHeader}>
                                    5000
                                </h2>
                                <p className={styles.outcomes__outcomeSubtext}>
                                    5000 teachers across underserved communities in Nigeria will become expert trainers using the BDEI curriculum, learning content and platform
                                </p>
                            </div>
                            {/*<div className={[styles.outcomes__outcome, styles.outcomes__outcomeYellow].join(" ")}>*/}
                            {/*    <h2 className={styles.outcomes__outcomeHeader}>*/}
                            {/*        100*/}
                            {/*    </h2>*/}
                            {/*    <p className={styles.outcomes__outcomeSubtext}>*/}
                            {/*        100 junior and senior secondary schools in underserved communities across 10 states*/}
                            {/*        will be beneficiaries of the BDEI program*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                            <div className={[styles.outcomes__outcome, styles.outcomes__outcomePurple].join(" ")}>
                                <h2 className={styles.outcomes__outcomeHeader}>
                                    500,000
                                </h2>
                                <p className={styles.outcomes__outcomeSubtext}>
                                    500,000 children will be empowered with skills set and STEM education necessary to becoming digitally literate
                                </p>
                            </div>
                        </div>
                        <img src={circlesIcon} className={styles.outcomes__outcomeCircleIcon} alt="circles"/>
                    </section>
                    <section className={styles.activities}>
                        <div className={styles.activities__textRow}>
                            <h4 className={styles.sectionHeader}>
                                ACTIVITY ROAD MAP
                            </h4>
                            <p className={styles.sectionSubtext}>
                                Find out how the program is being carried out
                            </p>
                            <p className={styles.sectionLetter}>
                                A
                            </p>
                            <span className={styles.activities__line}/>
                        </div>
                        <div>
                            <div className={styles.activities__splitLine}/>
                            <img src={circlesIcon} className={styles.activity__circle1} alt=""/>
                            <img src={circlesIcon} className={styles.activity__circle2} alt=""/>
                            <img src={circlesIcon} className={styles.activity__circle3} alt=""/>
                            <div className={styles.activity__row}>
                                <div className={styles.activity__imgWrapper}>
                                    <img src={SurveyIcon} alt=""/>
                                </div>
                                <div className={[styles.activity, styles.activityRight].join(" ")}>
                                    <p className={[styles.activity__number, styles.activity__numberRight].join(" ")}>
                                        01
                                    </p>
                                    <h3 className={styles.activity__header}>
                                        Baseline Survey
                                    </h3>
                                    <p className={styles.activity__subtext}>
                                        The baseline survey is to gather data on the current state of secondary schools in alignment with digital education. The survey would be carried out across 8 states in the 6 senatorial district area of the country.
                                        The result from the survey would be published.
                                    </p>
                                </div>
                                <span className={styles.activity__dot}/>
                            </div>
                            <div className={styles.activity__row}>
                                <div className={[styles.activity, styles.activityLeft].join(" ")}>
                                    <p className={[styles.activity__number, styles.activity__numberLeft].join(" ")}>
                                        02
                                    </p>
                                    <h3 className={styles.activity__header}>
                                        Champions training
                                    </h3>
                                    <p className={styles.activity__subtext}>
                                        This training is the Train the Master trainers program for all selected
                                        champions. The BDEI curriculum would be introduced to the selected BDEI
                                        champion.
                                    </p>
                                </div>
                                <div className={styles.activity__imgWrapper}>
                                    <img src={ChampionsIcon} alt=""/>
                                </div>
                                <span className={styles.activity__dot}/>
                            </div>
                            <div className={styles.activity__row}>
                                <div className={styles.activity__imgWrapper}>
                                    <img src={SchoolIcon} alt=""/>
                                </div>
                                <div className={[styles.activity, styles.activityRight].join(" ")}>
                                    <p className={[styles.activity__number, styles.activity__numberRight].join(" ")}>
                                        03
                                    </p>
                                    <h3 className={styles.activity__header}>
                                        School sign up
                                    </h3>
                                    <p className={styles.activity__subtext}>
                                        All interested schools would sign up on the portal.
                                    </p>
                                </div>
                                <span className={styles.activity__dot}/>
                            </div>
                            <div className={styles.activity__row}>
                                <div className={[styles.activity, styles.activityLeft].join(" ")}>
                                    <p className={[styles.activity__number, styles.activity__numberLeft].join(" ")}>
                                        04
                                    </p>
                                    <h3 className={styles.activity__header}>
                                        School Training commences
                                    </h3>
                                    <p className={styles.activity__subtext}>
                                        The training would be conducted in all participating schools. Teachers would be responsible for training their students using the BDEI curriculum for <span className={styles.activity__subtextBold}>6 months</span>
                                    </p>
                                </div>
                                <div className={styles.activity__imgWrapper}>
                                    <img src={TrainingIcon} alt=""/>
                                </div>
                                <span className={styles.activity__dot}/>
                            </div>
                            <div className={styles.activity__row}>
                                <div className={styles.activity__imgWrapper}>
                                    <img src={CupIcon} alt=""/>
                                </div>
                                <div className={[styles.activity, styles.activityRight].join(" ")}>
                                    <p className={[styles.activity__number, styles.activity__numberRight].join(" ")}>
                                        05
                                    </p>
                                    <h3 className={styles.activity__header}>
                                        Intrastate BDEI challenge
                                    </h3>
                                    <p className={styles.activity__subtext}>
                                        The BDEI Challenge between schools in the same state. Schools will be selected to qualify for the regional challenge
                                    </p>
                                </div>
                                <span className={styles.activity__dot}/>
                            </div>
                            <div className={styles.activity__row}>
                                <div className={[styles.activity, styles.activityLeft].join(" ")}>
                                    <p className={[styles.activity__number, styles.activity__numberLeft].join(" ")}>
                                        06
                                    </p>
                                    <h3 className={styles.activity__header}>
                                        Regional BDEI Challenge
                                    </h3>
                                    <p className={styles.activity__subtext}>
                                        The BDEI competition between different regions in Nigeria.
                                    </p>
                                </div>
                                <div className={styles.activity__imgWrapper}>
                                    <img src={PositionsIcon} alt=""/>
                                </div>
                                <span className={styles.activity__dot}/>
                            </div>
                            <div className={styles.activity__row}>
                                <div className={styles.activity__imgWrapper}>
                                    <img src={WinnerIcon} alt=""/>
                                </div>
                                <div className={[styles.activity, styles.activityRight].join(" ")}>
                                    <p className={[styles.activity__number, styles.activity__numberRight].join(" ")}>
                                        07
                                    </p>
                                    <h3 className={styles.activity__header}>
                                        National BDEI Challenge
                                    </h3>
                                    <p className={styles.activity__subtext}>
                                        This is the National BDEI competition where the best students in various regions
                                        compete for the ultimate prize
                                    </p>
                                </div>
                                <span className={styles.activity__dot}/>
                            </div>
                        </div>
                    </section>
                    <section className={styles.becomeAChampion}>
                        <p className={styles.becomeAChampion__text}>
                            Are you ready to become a BDEI champion?
                        </p>
                        <p className={styles.becomeAChampion__text}>
                            Sign up below
                        </p>
                        <Link to='/become-a-champion' className={styles.becomeAChampion__link}>
                            Become a BDEI champion
                        </Link>
                    </section>
                </main>
                <footer className={styles.footer}>
                <span className={styles.footer__text}>
                    &copy; BDEI 2020
                </span>
                    <div className="footer__icons">
                        <a href="https://www.instagram.com/tech4dev/" className="footer__social-media-icon"
                           target="_blank" rel="noopener noreferrer">
                            <img src={IGLogo} alt="" className={styles.footer__icon}/>
                        </a>
                        <a href="https://web.facebook.com/Tech4DevHQ/" className="footer__social-media-icon"
                           target="_blank" rel="noopener noreferrer">
                            <img src={FBLogo} alt="" className={styles.footer__icon}/>
                        </a>
                        <a href="https://twitter.com/Tech4DevHQ" className="footer__social-media-icon" target="_blank"
                           rel="noopener noreferrer">
                            <img src={TWLogo} alt="" className={styles.footer__icon}/>
                        </a>
                    </div>
                </footer>
            </>

        )
    }
}

export default AboutUs